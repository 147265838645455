import _axios from '../oauth2Api';
//import React from 'react';
import { getFormURLencoded } from '../utils';
import {
  getAuth,
  getMultiFactorResolver,
  TotpMultiFactorGenerator,
  PhoneMultiFactorGenerator,
  signInWithEmailAndPassword,
} from 'firebase/auth';

export function logAuth2In(user = { username: null, password: null, refresh_token: null, ipAccessToken: null }) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      _axios
        .post('/token', getFormURLencoded(user))
        .then((response) => {
          sessionStorage.setItem('jwtToken', response.data.access_token);
          sessionStorage.setItem('jwtRefreshToken', response.data.refresh_token);
          dispatch({
            type: 'USER_LOGIN_SUCCESS',
            payload: response.data,
          });
          resolve(response.data);
        })
        .catch((err) => {
          sessionStorage.removeItem('jwtToken');
          dispatch({
            type: 'USER_LOGIN_FAILURE',
            payload: err.data,
          });
          reject(err);
        });
    });
}

export function logIn(user = { username: null, password: null, refresh_token: null }) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      signInWithEmailAndPassword(getAuth(), user.username, user.password)
        .then(function (userCredential) {
          dispatch({
            type: 'USER_PLATFORM_VERIFIED',
            payload: userCredential,
          });
          resolve(userCredential);
        })
        .catch(function (error) {
          if (error.code === 'auth/multi-factor-auth-required') {
            const resolver = getMultiFactorResolver(getAuth(), error);
            if (resolver.hints[0].factorId === TotpMultiFactorGenerator.FACTOR_ID) {
              dispatch({
                type: 'USER_OTP_REQUEST',
                payload: resolver,
              });
              resolve(resolver);
            } else if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
              console.log('Phone SMS verification.');
            } else {
              console.log('The second factor is unsupported.');
            }
          } else {
            sessionStorage.removeItem('jwtToken');
            dispatch({
              type: 'USER_LOGIN_FAILURE',
              payload: {
                code: '4011-unauthorized',
                message: 'Bad credentials',
                reason: 'USERNAME_OR_PASSWORD_INVALID',
              },
            });
            reject(error);
          }
        });
    });
}

export function verifyToken(otp, resolver) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(resolver.hints[0].uid, otp);
      return resolver
        .resolveSignIn(multiFactorAssertion)
        .then(function (userCredential) {
          dispatch({
            type: 'USER_PLATFORM_VERIFIED',
            payload: userCredential,
          });
          resolve(userCredential);
        })
        .catch((e) => {
          dispatch({
            type: 'USER_LOGIN_FAILURE',
            payload: {
              code: e.code,
              message: 'Invalid or Expired Verification Code',
            },
          });
          reject(e);
        });
    });
}

export function select(user) {
  return function (dispatch) {
    dispatch({
      type: 'USER_SELECT',
      payload: user,
    });
    localStorage.setItem('userSelected', JSON.stringify(user));
  };
}

export function logOut() {
  return function (dispatch) {
    sessionStorage.removeItem('jwtToken');
    dispatch({
      type: 'USER_LOGOUT',
      payload: false,
    });
  };
}
