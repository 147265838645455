import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

import SchedulerForm from './containers/SchedulerForm';
import { fetchScheduler } from '../../../../actions/synchronisationActions';

function SyncScheduler({ loading, sourceId, scheduler, dispatch }) {
  React.useEffect(() => {
    if (sourceId) {
      dispatch(fetchScheduler(sourceId));
    }
  }, [sourceId, dispatch]);

  return (
    <div className="p-4">
      {loading ? (
        <div className="d-flex flex-row justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <SchedulerForm initialValues={scheduler} />
      )}
    </div>
  );
}

export default connect(state => ({
  sourceId: state.sync.source.currentSourceId,
  loading: state.sync.scheduler.loading,
  scheduler: state.sync.scheduler.scheduler
}))(SyncScheduler);
