const initialState = {
  loading: false,
  error: null,
  scheduler: {
    isActive: false,
    days: [],
    startHour: 0,
    endHour: 0,
    frequency: 'ONE_MIN'
  },
  updating: false,
  message: ''
};

export default function schedulerReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_SYNC_SCHEDULER_START':
      return { ...state, loading: true };

    case 'FETCH_SYNC_SCHEDULER_SUCCESS':
      return { ...state, loading: false, scheduler: action.payload.scheduler };

    case 'UPDATE_SYNC_SCHEDULER_START':
      return { ...state, updating: true };

    case 'UPDATE_SYNC_SCHEDULER_SUCCESS':
      return {
        ...state,
        updating: false,
        message: 'Successfully updated sync schduler',
        scheduler: action.payload.scheduler
      };

    case 'FETCH_SYNC_SCHEDULER_FAILED':
    case 'UPDATE_SYNC_SCHEDULER_FAILED': {
      return {
        ...state,
        loading: false,
        updating: false,
        message: action.payload.error.message,
        error: action.payload.error
      };
    }

    case 'SYNC_CLEAR_SOURCE_MESSAGE':
      return { ...state, message: '', error: null };
  }
  return state;
}
