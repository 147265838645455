import React, { Component } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';

import { loginTimeout } from '../utils';
import Header from '../components/Header/';
import Sidebar from '../components/Sidebar/';
import Breadcrumb from '../components/Breadcrumb/';
import Footer from '../components/Footer/';
import * as dataCentreActions from '../actions/dataCentreActions';
import * as userActions from '../actions/userActions';
import * as configActions from '../actions/configActions';

// PAGES
import Dashboard from '../views/Dashboard/';
import DataCentres from '../views/DataCentres/';
import Operators from '../views/Operators/';
import Stores from '../views/Stores/';
import Backups from '../views/Backups/';
import StoreStats from '../views/StoreStats/';
import Versions from '../views/Versions/';
import Synchronization from '../views/Synchronization/';
import ClientCredentials from '../views/ClientCredentials/';
import Settings from '../views/Settings/';
import StoreGateway from '../views/StoreGateway';
import XMDock from '../components/XMDock';
import OperatorForm from '../views/Operators/OperatorForm';
import { PrivateRoute } from '../routes/PrivateRoute';

@connect((state) => {
  return {
    config: state.config,
    dataCentre: state.dataCentre,
    user: state.user,
  };
})
class Root extends Component {
  state = {
    dock: false,
  };

  componentDidMount() {
    const { config, dataCentre, user, dispatch } = this.props;

    // load dataCentres
    if (!dataCentre.fetched) {
      dispatch(dataCentreActions.getAll())
        .then((dataCentres) => {
          // Last selected country or select the first in the dataCentres list as default
          let selectedCountry =
            localStorage.getItem('dataCentreSelected') && localStorage.getItem('dataCentreSelected') !== 'undefined'
              ? JSON.parse(localStorage.getItem('dataCentreSelected'))
              : dataCentres.content[0];

          dispatch(dataCentreActions.select(selectedCountry));
        })
        .catch((error) => {
          console.log('error', error);
          dispatch({
            type: 'DATACENTRE_GETALL_ERROR',
            payload: err.message,
          });
        });
    }

    if (!config.fetched) {
      dispatch(configActions.getAll());
    }

    // Logged user
    if (!user.selected && localStorage.getItem('userSelected')) {
      dispatch(userActions.select(JSON.parse(localStorage.getItem('userSelected'))));
    }

    this.logoutTimer = setInterval(() => {
      loginTimeout();
    }, 300000);
  }

  componentWillUnmount() {
    if (this.logoutTimer) {
      clearInterval(this.logoutTimer);
    }
  }

  handleSettingClick = () => {
    this.setState({ dock: true });
  };

  handleCancelSetting = () => {
    this.setState({ dock: false });
  };

  render() {
    const { dataCentre, user } = this.props;

    // LOAD APP only after dataCentre API returns
    if (!dataCentre.fetched) {
      return <div />;
    }

    return (
      <div className="app">
        <Header onSettingClick={this.handleSettingClick} />
        <XMDock
          isVisible={this.state.dock}
          title={user && user.selected && user.selected.name}
          handleCancel={this.handleCancelSetting}
        >
          <OperatorForm
            initialValues={user.selected}
            profileSetting={true}
            handleCancel={this.handleCancelSetting}
            toggle={this.handleCancelSetting}
          />
        </XMDock>
        <div className="app-body">
          <Sidebar {...this.props} />
          <main className="main">
            <Breadcrumb />
            <Container fluid>
              <Routes>
                <Route element={<PrivateRoute />}>
                  <Route path="/dashboard" name="Dashboard" element={<Dashboard />} />
                  <Route path="/datacentres" name="DataCenters" element={<DataCentres />} />
                  <Route path="/users" name="Users" element={<Operators />} />
                  <Route path="/stores" name="Stores" element={<Stores />} />
                  <Route path="/stores/backups" name="Backup" element={<Backups />} />
                  <Route path="/stores/:storeId/backup/*" name="Backup" element={<Backups />} />
                  <Route path="/stores/:storeId/stats/*" name="StoreStats" element={<StoreStats />} />
                  <Route path="/stores/credentials" name="ClientCredentials" element={<ClientCredentials />} />
                  <Route
                    path="/stores/:storeId/credentials/*"
                    name="ClientCredentials"
                    element={<ClientCredentials />}
                  />
                  <Route path="/stores/synchronization" name="Synchronization" element={<Synchronization />} />
                  <Route
                    path="/stores/:storeId/synchronization/*"
                    name="Synchronization"
                    element={<Synchronization />}
                  />
                  <Route path="/stores/gateway" name="Store Gateway" element={<StoreGateway />} />
                  <Route path="/stores/:storeId/gateway/*" name="Store Gateway" element={<StoreGateway />} />
                  <Route path="/stores/synchronization/settings" name="Settings" element={<Settings />} />
                  <Route path="/stores/:storeId/synchronization/settings" name="Settings" element={<Settings />} />
                  <Route path="/stores/:storeId/synchronization/sage" name="Sage Data" element={<Settings />} />
                  <Route path="/versions" name="Versions" element={<Versions />} />
                </Route>
                <Route path="*" element={<Navigate to="/dashboard" replace />} />
              </Routes>
            </Container>
          </main>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Root;
