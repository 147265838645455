import React, { Component } from 'react';
import { Button, Col, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as oauth2Actions from '../../actions/clientCredentialActions';
import XMAuditTable from '../../components/XMAuditTable';

const mapStateToProps = state => ({
  currentStore: state.store.one
});

// SETUP REDUX FORM
@reduxForm({
  form: 'clientCredentialForm',
  enableReinitialize: true
})
// CONNECT TO REDUX
@connect(mapStateToProps)
class ClientCredentialForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.state = {
      isFormVisible: true,
      clientId: null,
      clientSecret: null,
    };
  }

  submit = clientCredential => {
    const { dispatch, currentStore } = this.props;

    // new
    if (!clientCredential.id) {
      clientCredential = {
        ...clientCredential,
        storeId: currentStore.storeId
      };
    }

    dispatch(oauth2Actions.register(clientCredential))
    .then((response) => {
      this.setState({ clientId: response.data.clientId, clientSecret: response.data.clientSecret, isFormVisible: false });
    })
  };

  onCancel() {
    const { handleCancel, toggle } = this.props;
    handleCancel();
    toggle();
  }

  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  render() {
    const {
      handleSubmit
    } = this.props;
    return (
      <div>
        {this.state.isFormVisible ? (
          <>
            <form onSubmit={handleSubmit(this.submit)} className='form-horizontal'>
              <FormGroup row>
                <Col md='3'>
                  <label htmlFor='clientName'>Client Name</label>
                </Col>
                <Col md='8'>
                  <Field
                    name='clientName'
                    component='input'
                    type='text'
                    className='form-control'
                    required={true}
                  />
                </Col>
              </FormGroup>
              <div className='float-end'>
                <Button
                  type='button'
                  color='secondary'
                  className='me-2'
                  onClick={() => this.onCancel()}
                >
                  Cancel
                </Button>
                <Button type='submit' color='primary'>
                  Save
                </Button>
              </div>
            </form>
          </>
        ) : (
          <>
            <p>
              <strong>Client Id:</strong>&nbsp;
              <span className="bg-light">{this.state.clientId}</span>
              <button
                type={'button'}
                className="btn-transparent btn btn-secondary"
                onClick={() => this.copyToClipboard(this.state.clientId)}
              >
                <i className="fa fa-clipboard" aria-hidden="true"></i>
              </button>
            </p>

            <p>
              <strong>Client Secret:</strong>&nbsp;
              <span className="bg-light">{this.state.clientSecret}</span>
              <button
                type={'button'}
                className="btn-transparent btn btn-secondary"
                onClick={() => this.copyToClipboard(this.state.clientSecret)}
              >
                <i className="fa fa-clipboard" aria-hidden="true"></i>
              </button>
            </p>

            <div className='float-end'>
              <Button
                type='button'
                color='primary'
                onClick={() => this.onCancel()}
              >
                Close
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ClientCredentialForm;
