import React, { Component } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Root from './Root';
import Login from '../views/Login';
import { PrivateRoute } from '../routes/PrivateRoute';

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />} >
            <Route path="*" element={<Root />} />
          </Route>
        </Routes>
      </HashRouter>
    );
  }
}

export default App;
