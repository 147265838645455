import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './stores/';
import App from './containers/App';
import { initializeApp } from 'firebase/app';
import { StoreManagerApi } from './api';

// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import '../scss/style.scss';

const container = document.getElementById('root');
StoreManagerApi.getFBConfig().then(res=>{
  initializeApp(res);
});
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
